import "tailwindcss/tailwind.css";
import "../styles/globals.css";
import BtcInfoProvider from "../components/general/SatoshiPriceProvider";
import PageChangeProvider from "../components/general/PageChangeProvider";
import { FirebaseAppProvider } from "reactfire";
import { enableFirebaseEmulator, preloadSDKs } from "../lib/firebaseEmulator";
import { ApolloProvider } from "@apollo/client";
import GeneralSettingsProvider from "../components/general/maintenance/GeneralSettingsProvider";
import { ThemeProvider, Theme, StyledEngineProvider, createTheme } from '@mui/material/styles';
import client from "../lib/graphql";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


// Material UI top theme
const theme = createTheme();

function MyApp({ Component, pageProps }) {
  const firebaseApp = enableFirebaseEmulator();
  return (
    <PageChangeProvider>
      <BtcInfoProvider>
        <FirebaseAppProvider firebaseApp={firebaseApp}>
          <ApolloProvider client={client}>
              <GeneralSettingsProvider>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <Component {...pageProps} />
                  </ThemeProvider>
              </StyledEngineProvider>
              </GeneralSettingsProvider>
          </ApolloProvider>
        </FirebaseAppProvider>
      </BtcInfoProvider>
    </PageChangeProvider>
  );
}

export default MyApp;
