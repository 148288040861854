import { devEnv } from "./development";
import { IEnvironmentType, LnRouterEnvType } from "./environmentType";
import { prodEnv } from "./production";
import { stagEnv } from "./staging";


function getLnRouterEnv(): LnRouterEnvType {
  const LNROUTER_ENV = process?.env?.LNROUTER_ENV;

  if(LNROUTER_ENV) {
    return LNROUTER_ENV as LnRouterEnvType;
  }

  const isSSR = typeof window === 'undefined';
  if (isSSR) {
    return 'development';
  }
  const hostname = window?.location?.hostname;
  if (hostname === devEnv.domain) {
    return 'development';
  } else if (hostname === stagEnv.domain) {
    return  'staging';
  } else if (hostname === prodEnv.domain) {
    return 'production'
  }
}


const lnrouterEnv = getLnRouterEnv();

if (lnrouterEnv !== 'production') {
  console.log('Environment', lnrouterEnv);
}


let specialEnv: IEnvironmentType;
switch(lnrouterEnv) {
  case 'production': {
    specialEnv = prodEnv;
    break;
  };
  case 'staging': {
    specialEnv = stagEnv;
    break;
  };
  default: {
    specialEnv = devEnv;
  }
}


export default {
  ...specialEnv,
};
