import { preloadAuth, preloadFirestore, preloadFunctions } from "reactfire";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { firebaseConfig } from "./firebase-client";
import env from './env';

export const preloadSDKs = (firebaseApp) => {
  return Promise.all([
    preloadFirestore({ firebaseApp }),
    preloadAuth({ firebaseApp })
  ]);
};


export const enableFirebaseEmulator = () => {
  if (firebase.apps.length > 0) {
    return firebase.apps[0];
  }
  const isEmulator = !env.isProduction;
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  // Workaround for user login
  // https://github.com/FirebaseExtended/reactfire/issues/249
  const auth = firebaseApp.auth();
  if (isEmulator) {
    (auth as any).useEmulator("http://localhost:9099", { disableWarnings: true });
  }
  const functions = firebaseApp.functions();
  if (isEmulator) {
    functions.useEmulator("localhost", 5001);
  }
  const firestore = firebaseApp.firestore();
  if (isEmulator) {
    firestore.settings({ host: "localhost:8080", ssl: false });
  }

  preloadAuth({
    firebaseApp,
    setup: async (factory) => {
      if (isEmulator) {
        auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      }
      return auth;
    },
  });

  preloadFirestore({
    firebaseApp,
    // @ts-ignore: Unreachable code error
    setup: (factory) => {
      return firestore;
    },
  });

  preloadFunctions({
    firebaseApp,
    // @ts-ignore: Unreachable code error
    setup: (instanceFactory) => {
      return functions;
    },
  });

  return firebaseApp;
};
