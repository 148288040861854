import env from "./env";

export const GA_TRACKING_ID = 'G-XEH3FTCN0E';


// log the pageview with their URL
export const pageview = (url:string , pageTitle: string) => {
    if (!env.isProduction) {
        return;
    }
    if (!(window as any).gtag) {
      return;
    }
    (window as any).gtag('config', GA_TRACKING_ID, {
      page_path: url,
      page_title: pageTitle
    })
  }
  
  // log specific events happening.
  export const logGaEvent = (action: string, params: any) => {
    if (!env.isProduction) {
        return;
    }
    if (!(window as any).gtag) {
      return;
    }
    const gtag = (window as any).gtag;
    gtag('event', action, params);
  }