

export const firebaseConfig = {
    apiKey: "AIzaSyDfVCphSOqcGJ2GRq7e5XVdKEVo0G_uuxQ",
    authDomain: "lnrouter.firebaseapp.com",
    projectId: "lnrouter",
    storageBucket: "lnrouter.appspot.com",
    messagingSenderId: "530141500984",
    appId: "1:530141500984:web:fbff1bd3707fcedab1f8cd"
};
