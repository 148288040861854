

import { useRouter } from 'next/router';
import * as React from 'react'
import { createContext } from "react";
import { pageview } from '../../lib/ga';

export interface PageChange {
    path: string;
    title: string;
}

export const PageChangeContext = createContext<PageChange>({
    path: undefined,
    title: undefined
});



type Props = {
    children: any
}

const PageChangeProvider = ({ children }: Props) => {
    const [page, setPage] = React.useState<PageChange>(undefined);
    const router = useRouter()

    React.useEffect(() => {
        setPage({
            path: window.location.pathname,
            title: window.document.title
        })
    }, []);

    React.useEffect(() => {
      const handleRouteChange = (url) => {
        setPage({
            path: url,
            title: window.document.title
        })
      }
      //When the component is mounted, subscribe to router changes
      //and log those page views
      router.events.on('routeChangeComplete', handleRouteChange)
  
      // If the component is unmounted, unsubscribe
      // from the event with the `off` method
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }, [router.events])

    React.useEffect(() => {
        if (!page) {
            return;
        }
        pageview(page.path, page.title);
    }, [page]);
    return (
        <PageChangeContext.Provider value={page}>
            {children}
        </PageChangeContext.Provider>
    );
};

export default PageChangeProvider;