

import * as React from 'react'
import { createContext } from "react";
import { useFirestore, useFirestoreDocData } from 'reactfire';




export interface IGeneralSettings {
  isMaintenance: boolean
}

export const GeneralSettingsContext = createContext<IGeneralSettings>({
  isMaintenance: false
});



type Props = {
  children: any
}

const GeneralSettingsProvider = ({ children }: Props) => {
  const docRef = useFirestore().collection('globalSettings').doc('general');
  const doc = useFirestoreDocData(docRef)

  const isMaintenance = !!(doc.data as any)?.isMaintenance;

  return (
    <GeneralSettingsContext.Provider value={{
      isMaintenance: isMaintenance
    }}>
      {children}
    </GeneralSettingsContext.Provider>
  );
};

export default GeneralSettingsProvider;


export function useGeneralSettings() {
  return React.useContext(GeneralSettingsContext)
}