import { IEnvironmentType } from "./environmentType";

export const prodEnv: IEnvironmentType = {
    isProduction: true,
    apiUrl: 'https://api.lnrouter.app/',
    graphqlApi: 'https://api.lnrouter.app/graphql',
    lnrouter_env: 'production',
    domain: 'lnrouter.app',
    pricing: [
        {
            months: 1,
            offPercent: 0,
            monthlyPriceSat: 25200,
            id: 1,
        },
        {
            months: 3,
            offPercent: 0.05,
            monthlyPriceSat: 23940,
            id: 2,
        },
        {
            months: 6,
            offPercent: 0.1,
            monthlyPriceSat: 22680,
            id: 3,
        },
    ]
}
