

import * as React from 'react'
import { createContext } from "react";


export interface IBtcInfo {
  btcUsdPrice: number;
  satoshiUsdPrice: number,
  currentBlockHeight: number,
  transactionFeesSatPerVByte: {
    low: number,
    medium: number,
    high: number
  }
}

export const BtcInfoContext = createContext<IBtcInfo>({
  btcUsdPrice: undefined,
  satoshiUsdPrice: undefined,
  currentBlockHeight: undefined,
  transactionFeesSatPerVByte: {
    low: undefined,
    medium: undefined,
    high: undefined
  }
});



type Props = {
  children: any
}

const BtcInfoProvider = ({ children }: Props) => {
  const [btcPrice, setBtcPrice] = React.useState<number>(undefined);
  const [currentBlockHeight, setCurrentBlockHeight] = React.useState<number>(undefined);
  const [feeLow, setFeeLow] = React.useState<number>(undefined);
  const [feeMedium, setFeeMedium] = React.useState<number>(undefined);
  const [feeHigh, setFeeHigh] = React.useState<number>(undefined);



  const loadPrice = async function () {
    const response = await fetch('https://api.coindesk.com/v1/bpi/currentprice.json');
    const json = await response.json();
    const btcPrice = json.bpi.USD.rate_float;
    setBtcPrice(btcPrice);
  }
  const loadBlockHeight = async function () {
    const response = await fetch('https://mempool.space/api/blocks/tip/height');
    const json = await response.json();
    setCurrentBlockHeight(json);
  }

  const loadTransactionFees = async function () {
    const response = await fetch('https://mempool.space/api/v1/fees/recommended');
    const json = await response.json();
    setFeeLow(json.hourFee);
    setFeeMedium(json.halfHourFee);
    setFeeHigh(json.fastestFee);
  }



  React.useEffect(() => {
    loadPrice()
    loadBlockHeight()
    loadTransactionFees()
  }, []);
  let satoshiPrice = undefined;
  if (btcPrice) {
    satoshiPrice = btcPrice / 100000000
  }

  const data = {
    btcUsdPrice: btcPrice,
    satoshiUsdPrice: satoshiPrice,
    currentBlockHeight: currentBlockHeight,
    transactionFeesSatPerVByte: {
      low: feeLow,
      medium: feeMedium,
      high: feeHigh
    }
  };
  return (
    <BtcInfoContext.Provider value={data}>
      {children}
    </BtcInfoContext.Provider>
  );
};

export default BtcInfoProvider;


export function useBtcInfo() {
  return React.useContext(BtcInfoContext);
}